var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-9 pb-2"},[_c('nav',{attrs:{"aria-label":"..."}},[_c('ul',{staticClass:"pagination text-smallest mb-0 pb-0"},[_c('li',{staticClass:"page-item",class:{ 'disabled': _vm.pagination.currentPage === 1}},[_c('button',{staticClass:"page-link",on:{"click":function($event){return _vm.setPage(1)}}},[_vm._v("First")])]),_vm._l((_vm.paginationPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ 'disabled': page === '...', 'active': page === _vm.pagination.currentPage }},[_c('button',{staticClass:"page-link",on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item",class:{ 'disabled': _vm.pagination.currentPage === _vm.pagination.lastPage}},[_c('button',{staticClass:"page-link",on:{"click":function($event){return _vm.setPage(_vm.pagination.lastPage)}}},[_vm._v("Last")])])],2)])]),_c('div',{staticClass:"col-sm-3 text-right pb-2"},[_c('button',{staticClass:"e-btn e-btn-blue-gray force-white-all",on:{"click":_vm.generateFile}},[_c('font-awesome-icon',{attrs:{"icon":"file"}}),_vm._v(" GENERATE PDF ")],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table small w-100",attrs:{"id":"airListTbl"}},[_vm._m(0),_c('tbody',[(_vm.isLoading)?[_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('app-loading')],1)])]:_vm._l((_vm.reports),function(report,index){return _c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"align-middle text-center"},[_vm._v(_vm._s(report.vessel))]),_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(report.management_office ? report.management_office.toUpperCase() : '')+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(report.title ? report.title.toUpperCase() : ''))]),_c('td',{staticClass:"align-middle text-white text-center",class:{
            'bg-04a44c': !report.report_status_id || report.report_status_id===1,
            'bg-056cb4': report.report_status_id != null && report.report_status_id===2,
            'bg-4c4c4c': report.report_status_id != null && report.report_status_id===3,
            'bg-042454': report.report_status_id != null && report.report_status_id===4,
            'bg-bc8526': report.report_status_id != null && report.report_status_id===5,
            }},[_vm._v(_vm._s(report.report_status ? report.report_status.toUpperCase() : 'NEW')+" ")]),_c('td',{staticClass:"align-middle text-center"},[_vm._v(_vm._s(_vm._f("moment")(report.event_date,'D MMM YYYY')))]),_c('td',{staticClass:"align-middle text-center"},[_c('button',{staticClass:"e-btn e-btn-blue e-btn-sm e-text-white",on:{"click":function($event){return _vm.showReport(report.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}}),_vm._v(" View ")],1)])])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-white"},[_c('tr',[_c('th',{staticClass:"align-middle text-center",staticStyle:{"width":"1em"},attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{staticClass:"align-middle text-center",attrs:{"scope":"col"}},[_vm._v("VESSEL")]),_c('th',{staticClass:"align-middle text-center",attrs:{"scope":"col"}},[_vm._v("MANAGER")]),_c('th',{staticClass:"align-middle text-center",staticStyle:{"min-width":"25em"},attrs:{"scope":"col"}},[_vm._v("REPORT TITLE")]),_c('th',{staticClass:"align-middle text-center",staticStyle:{"width":"5em"},attrs:{"scope":"col"}},[_vm._v("STATUS")]),_c('th',{staticClass:"align-middle text-center",staticStyle:{"min-width":"9em"},attrs:{"scope":"col"}},[_vm._v("EVENT DATE")]),_c('th',{staticClass:"align-middle text-center",attrs:{"scope":"col"}},[_vm._v("ACTION")])])])
}]

export { render, staticRenderFns }