<template>
  <div>
    <div class="row align-items-center">
      <div class="col-sm-9 pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-3 text-right pb-2">
        <button class="e-btn e-btn-blue-gray force-white-all" @click="generateFile">
          <font-awesome-icon icon="file" /> GENERATE PDF
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table small w-100" id="airListTbl">
          <thead class="bg-white">
          <tr>
            <th class="align-middle text-center" style="width: 1em" scope="col">#</th>
            <th class="align-middle text-center" scope="col">VESSEL</th>
            <th class="align-middle text-center" scope="col">MANAGER</th>
            <th class="align-middle text-center" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle text-center" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle text-center" style="min-width: 9em" scope="col">EVENT DATE</th>
            <th class="align-middle text-center" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="isLoading">
            <tr>
              <td colspan="100%">
                <app-loading></app-loading>
              </td>
            </tr>

          </template>
          <template v-else>
            <tr v-for="(report,index) in reports">
              <td>{{ index + 1 }}</td>
              <td class="align-middle text-center">{{ report.vessel }}</td>
              <td class="align-middle text-center">
                {{ report.management_office ? report.management_office.toUpperCase() : '' }}
              </td>
              <td class="align-middle">{{ report.title ? report.title.toUpperCase() : '' }}</td>
              <td class="align-middle text-white text-center" :class="{
              'bg-04a44c': !report.report_status_id || report.report_status_id===1,
              'bg-056cb4': report.report_status_id != null && report.report_status_id===2,
              'bg-4c4c4c': report.report_status_id != null && report.report_status_id===3,
              'bg-042454': report.report_status_id != null && report.report_status_id===4,
              'bg-bc8526': report.report_status_id != null && report.report_status_id===5,
              }">{{ report.report_status ? report.report_status.toUpperCase() : 'NEW' }}
              </td>
              <td class="align-middle text-center">{{ report.event_date | moment('D MMM YYYY') }}</td>
              <td class="align-middle text-center">
                <button class="e-btn e-btn-blue e-btn-sm e-text-white"
                        @click="showReport(report.id)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoading from "@/components/elements/AppLoading";
import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import axios from "axios";
import {GlobalService} from "@/services/GlobalService";
import {GENERATE_AIR_VESSEL_HISTORY} from "@/services/ConstantService";
import {ReportService} from "@/services/ReportService";

export default {
  name: "AccidentIncidentHistory",
  data() {
    return {
      isLoading: false,
      params: {
        vesselId: this.$route.params.id
      }
    }
  },

  mixins: [PaginationMixin],
  components: {AppLoading},
  methods: {
    ...mapActions(['getReports']),
    async showReport(reportId) {
      const route = this.$router.resolve(
        {
          name: 'AccidentIncidentReport',
          params: {
            id: reportId,
          },
        },
      )
      window.open(route.href, '_blank')
    },
    setPage(page) {
      this.pagination.currentPage = page;
      this.params.page = page
      this.getReports(this.params)
    },
    async generateFile() {
      swal.fire({
        'title': `GENERATING HISTORY FILE <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      await axios({
        url: GENERATE_AIR_VESSEL_HISTORY+`?vesselId=${this.$route.params.id}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          let filename = `ACCIDENT_INCIDENT_REPORT_HISTORY_${this.$route.params.id}_${Date.now()}.pdf`;
          let mime_type = 'application/pdf';
          let url = window.URL.createObjectURL(new Blob([response.data],{type:mime_type}));
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          swal.close()
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          GlobalService.handleError(error, 'DOWNLOAD ACCIDENT INCIDENT REPORT TEMPLATE SERVICE')
          return false
        })
    }
  },
  computed: {
    ...mapGetters(['reports'])
  },
  async created() {
    this.isLoading = true;
    await this.getReports(this.params)
    this.isLoading = false;
  }
}


</script>

<style scoped>
table tr td {
  font-size: 10px;
}

#airListTbl {
  width: 100%;
}

#airListTbl thead tr th {
  background-color: #e1e1e1;
  padding: .35rem .5rem;
}

#airListTbl tbody tr td, #airListTbl tbody tr th {
  border: 1px solid #e1e1e1;
}


</style>
